"use client";
import { Open_Sans } from "next/font/google";

import Error from "./error";

import "@/styles/globals.css";

const openSans = Open_Sans({
  subsets: ["latin"],
  variable: "--font-openSans",
  display: "swap",
});

export default function GlobalError(errProps: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html className={openSans.variable}>
      <body>
        <Error {...errProps} />
      </body>
    </html>
  );
}
